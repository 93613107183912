<template>
  <div class="artist-ui" id="artist-ui-v2" v-loading.fullscreen.lock="loading">
    <ArtistDetailLimitedContentV2
      :artistDetail="artistDetail"
      v-if="!loading"
    />
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { getSocialSitesFromChartMetricArray } from "@/utils/staticHelper";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
import { fetchArtistDataV2FromMongo } from "@/api/mongoDB";
import ArtistDetailLimitedContentV2 from "@/artists/views/ArtistDetailLimitedContentV2.vue";

export default {
  name: "ArtistDetailLimitedV2",
  components: {
    ArtistDetailLimitedContentV2,
  },
  setup() {
    const artist = ref({
      uuid: "",
    });
    const route = useRoute();
    const artistDetail = ref({});
    const chartMetricContacts = ref({});
    const loading = ref(false);
    onMounted(async () => {
      try {
        loading.value = true;
        const { data } = await fetchArtistDataV2FromMongo(
          route.params.spotifyID
        );
        artistDetail.value = data;
        chartMetricContacts.value = getSocialSitesFromChartMetricArray(
          data.chartmetricContacts && data.chartmetricContacts.contacts
            ? data.chartmetricContacts.contacts
            : ""
        );
        loading.value = false;
      } catch (e) {
        ElNotification({
          title: "Error",
          message: e.message,
          type: "error",
        });
        console.error(e);
      }
    });
    return {
      artist,
      artistDetail,
      loading,
      chartMetricContacts,
      route,
    };
  },
};
</script>
