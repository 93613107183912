<template>
  <div class="artist-main-content" @scroll="handleScroll()">
    <div id="artist_detail_v2" class="row artist-detail-v2">
      <ArtistPerformanceV2
        :chartmetric-spotify-listeners-total="
          artistDetail && artistDetail.chartmetricSpotifyListenersTotal
            ? artistDetail.chartmetricSpotifyListenersTotal
            : 0
        "
        :chartmetric-spotify-followers-growth="
          artistDetail && artistDetail.chartmetricSpotifyFollowersGrowth
            ? artistDetail.chartmetricSpotifyFollowersGrowth
            : {}
        "
        :chartmetric-spotify-listeners-growth="
          artistDetail && artistDetail.chartmetricSpotifyListenersGrowth
            ? artistDetail.chartmetricSpotifyListenersGrowth
            : {}
        "
        :spotify-listeners-trend-growth="
          artistDetail.spotifyListenersTrendGrowth
            ? artistDetail.spotifyListenersTrendGrowth
            : {}
        "
        :public="true"
      />
      <div id="top_10_songs" class="remove-top-margin">
        <ArtistTop10Songs :artistDetail="artistDetail" :public="true" />
      </div>
      <div id="playlists_main_tab">
        <ArtistPlaylistsV2
          :cm-statistics="
            artistDetail &&
            artistDetail.chartmetricMeta &&
            artistDetail.chartmetricMeta.cm_statistics
              ? artistDetail.chartmetricMeta.cm_statistics
              : {}
          "
          :playlists-subscriber-growth="
            artistDetail && artistDetail.playlistsSubscriberGrowth
              ? artistDetail.playlistsSubscriberGrowth
              : {}
          "
          :playlists-subscriber-growth-editorial="
            artistDetail && artistDetail.playlistsSubscriberGrowthEditorial
              ? artistDetail.playlistsSubscriberGrowthEditorial
              : {}
          "
          :public="true"
        />
      </div>
      <div id="artist_top_songs_catalog">
        <div id="top_10_tracks_on_spotify">
          <SpotifyTop10TracksV2
            :tracks="tracks"
            v-if="tracks && tracks.length && !loading"
            :public="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArtistPerformanceV2 from "@/artists/components/ArtistPerformanceV2.vue";
import ArtistPlaylistsV2 from "@/artists/components/ArtistPlaylistsV2.vue";
import ArtistTop10Songs from "@/artists/components/ArtistTop10Songs.vue";
import { onBeforeMount, ref, onMounted } from "vue";
import {
  getUniqueValuesFromArray,
  scrollToElementWithMargin,
} from "@/utils/staticHelper";
import $ from "jquery";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getTop10TracksFromMongo } from "@/api/mongoTracks";
import SpotifyTop10TracksV2 from "@/artists/components/SpotifyTop10TracksV2.vue";

export default {
  name: "ArtistDetailLimitedContentV2",
  components: {
    SpotifyTop10TracksV2,
    ArtistPerformanceV2,
    ArtistPlaylistsV2,
    ArtistTop10Songs,
  },
  props: {
    artistDetail: {
      type: Object,
    },
  },
  setup() {
    const activeTab = ref("overview_v2");
    const activeSubList = ref("artist_bio");
    const subListArray = ref([
      { tabName: "Artist Bio", id: "artist_bio" },
      { tabName: "Career Status", id: "career_status" },
      { tabName: "Growth Rates", id: "growth_rates" },
      { tabName: "Listeners vs Followers", id: "artist_ratios" },
      { tabName: "Score", id: "artist_scores" },
      { tabName: "Statistics", id: "artist_statistics" },
    ]);
    const store = useStore();
    const route = useRoute();

    const tracks = ref([]);
    const loading = ref(false);

    const getTopTracks = async () => {
      try {
        loading.value = true;
        let { data } = await getTop10TracksFromMongo(
          route.params.spotifyID,
          15
        );
        tracks.value = data;

        tracks.value = getUniqueValuesFromArray(data, "track");
        data = [];
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      if (route.params.spotifyID) {
        await store.dispatch("ArtistModule/getArtistListeners", {
          spotifyID: route.params.spotifyID,
          years: 3,
        });
      }
      await getTopTracks();
    });

    onBeforeMount(() => {
      window.addEventListener("scroll", handleScroll);
    });
    const handleScroll = () => {
      const scrollPos = $(document).scrollTop() + 250;
      $("#artist_menu_list a").each(function () {
        const currLink = $(this);
        const refElement = $(currLink.data("section-main"));
        if (
          refElement.position().top <= scrollPos &&
          refElement.position().top + refElement.height() > scrollPos
        ) {
          $("#artist_menu_list li a").removeClass("active");
          changeTab(currLink.data("section-main"));
          currLink.addClass("active");
        } else {
          currLink.removeClass("active");
        }
      });
      if (scrollPos === 250 || scrollPos < 250) {
        $("#overview_tab").addClass("active");
        changeTab("#overview_v2");
        activeSubList.value = "artist_bio";
      }
      $("#artist_sub_sub_menu_list li span").each(function () {
        const currList = $(this);
        const refList = $(currList.attr("data-section"));

        if (
          refList.position().top <= scrollPos &&
          refList.position().top + refList.height() > scrollPos
        ) {
          activeSubList.value = currList.attr("data-section").replace("#", "");
        }
      });
    };

    const changeTab = (tabName) => {
      activeTab.value = tabName;
      if (tabName === "#overview_v2") {
        subListArray.value = [
          { tabName: "Artist Bio", id: "artist_bio" },
          { tabName: "Career Status", id: "career_status" },
          { tabName: "Growth Rates", id: "growth_rates" },
          { tabName: "Listeners vs Followers", id: "artist_ratios" },
          { tabName: "Score", id: "artist_scores" },
          { tabName: "Statistics", id: "artist_statistics" },
        ];
      } else if (tabName === "#performance") {
        subListArray.value = [
          { tabName: "Monthly Listeners", id: "monthly_listeners" },
          { tabName: "Listeners Trend", id: "listener_trend" },
          { tabName: "Popularity", id: "popularity" },
          { tabName: "Followers", id: "monthly_follower" },
        ];
      } else if (tabName === "#playlists_main_tab") {
        subListArray.value = [
          { tabName: "Playlists", id: "playlists" },
          { tabName: "Total Playlists", id: "total_playlists" },
          { tabName: "New Playlists", id: "new_playlists" },
          { tabName: "Total Playlist Subscribers", id: "playlist_subscribers" },
          {
            tabName: "New Playlist Subscribers",
            id: "new_playlist_subscribers",
          },
        ];
      } else if (tabName === "#spotify_tab") {
        subListArray.value = [
          {
            tabName: "Monthly Listeners by Country",
            id: "spotify_monthly_listeners_by_country",
          },
        ];
      } else if (tabName === "#social_media") {
        subListArray.value = [
          { tabName: "Fanbase Growth Overtime", id: "growth_overtime" },
          {
            tabName: "Fanbase Engagement Overtime",
            id: "fanbase_engagement_overtime",
          },
          { tabName: "Total Fanbase Distribution", id: "fanbase_distribution" },
          { tabName: "Fanbase Growth", id: "fanbase_growth" },
          // { tabName: "Weekly Post Frequency", id: "weekly_post_frequency" },
        ];
      } else if (tabName === "#top_10_songs") {
        subListArray.value = [
          {
            tabName: "Top Tracks",
            id: "top_10_tracks_on_spotify",
          },
          {
            tabName: "Catalogue Age Metrics",
            id: "catalogue_age_metrics",
          },
          {
            tabName: "Spotify Catalog Age Graph",
            id: "spotify_catalog_age_graph",
          },
          {
            tabName: "Catalogue Age vs Streams Last Month",
            id: "age_vs_streams_last_month",
          },
          {
            tabName: "Total Streams vs Streams Last Month",
            id: "total_vs_streams_last_month",
          },
        ];
      }
      // else if (tabName === "#revenues_v2") {
      //   subListArray.value = [
      //     {
      //       tabName: "Revenue past 12 months",
      //       id: "revenue_past_12_months",
      //     },
      //     {
      //       tabName: "Artist Forecast",
      //       id: "artist_forecast",
      //     },
      //   ];
      // }
      else {
        subListArray.value = [];
      }
    };
    return {
      subListArray,
      activeTab,
      activeSubList,
      changeTab,
      scrollToElementWithMargin,
      handleScroll,
      tracks,
      loading,
    };
  },
};
</script>
<style lang="scss" scoped>
.remove-top-margin {
  margin-top: -10px !important;
}
.artist-detail-card-top {
  top: 0 !important;
  bottom: 1.5% !important;
}

.artist-detail-v2 {
  --bs-gutter-x: 0 !important;
}

input::placeholder {
  color: #33647f !important;
  font-size: 12px;
  font-weight: 400;
}

.top-menu-search {
  max-width: 300px;

  .search-bar {
    position: relative;
    width: -webkit-fill-available;
    margin-right: 1rem;
    margin-top: -5px;
  }

  .search-field {
    padding: 0.5rem 1.5rem !important;
    line-height: 0 !important;
  }

  .search-icon {
    position: absolute;
    right: 2%;
    font-size: 24px;
    bottom: 30%;
    fill: #33647f;
  }
}

.artist-main-content {
  .card {
    .list-inline {
      .list-inline-item {
        margin-left: 0;
        margin-right: 4px;

        .list-text {
          font-size: 12px;
          color: #33647f;
          font-weight: 400;
        }
      }
    }
  }
}

//
//@media only screen and (max-width: 1024px) {
//  .search-icon {
//    bottom: 55%;
//  }
//}

//@media only screen and (max-width: 1024px) {
//  .artist-detail-card {
//    .card {
//      .list-inline {
//        .list-inline-item {
//          margin-top: 10px;
//          margin-left: 0.25rem;
//        }
//      }
//    }
//  }
//}

.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

.sub-list-inline {
  padding: 2px !important;
}

.artist-sub-navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 11;
  transition: 0.8s;
}

.active-sub-text {
  color: black !important;
  font-weight: 600 !important;
}

@media only screen and (max-width: 1200px) {
  .artist-main-content {
    .card {
      .list-inline {
        .list-inline-item {
          margin-left: 0;
          margin-right: 2px;

          .social-icon {
            font-size: 10px;
          }

          .list-text {
            font-size: 10px;
          }
        }
      }
    }
  }
  .top-menu-search {
    max-width: 200px;

    .search-icon {
      bottom: 35%;
    }
  }
}
</style>
